#homeContent {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

#homeWelcome {
  padding-left: 60px;
  padding-top: 3%;
  width: 1200px;
}

#homeCentera {
  padding-top: 4vh;
  padding-left: 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3%;
  box-sizing: border-box;
  width: 100%;
  padding-right: 2vw;
  grid-template-rows: repeat(4, auto);
  height: fit-content;
  padding-bottom: 4vh;
}

#plusIcon {
  width: 55px;
  height: 55px;
  margin-right: 10px;
}

#option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0px 0px 50px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  width: 350px;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}
