#homeCenter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 50px;
  padding-left: 490px;
  grid-template-rows: repeat(4, auto);
  height: fit-content;
  padding-bottom: 58px;
}

#home-card {
  border-radius: 21px;
  box-shadow: 0px 2px 4px -1px #00000012;
  box-shadow: 0px 4px 6px -1px #0000001f;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: 200ms;
}

#home-card h1 {
  text-align: center;
}

#home-card:hover {
  box-shadow: 0px 4px 10px -1px #0000009a;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}

#home-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#home-card:nth-child(4) img {
  object-fit: contain;
}

#home-card:nth-child(5) img {
  object-fit: contain;
}

#home-card:nth-child(11) img {
  object-fit: contain;
}

#home-card:nth-child(8) img {
  object-fit: contain;
}
#home-card:nth-child(9) img {
  object-fit: contain;
}

#home-card:nth-child(12) img {
  object-fit: contain;
}
