    .botaoEnviar{
        background-color: #1E90FF;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 3px;
        margin-right: 8px;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }
    