  .TitleContainer {
    font-size: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin: 40px 0px 30px 75px;
    display: flex;
    flex-direction: column;
    margin-left: 45%;
    width: 650px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }