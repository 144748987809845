#TopContainer1{
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-bottom: 20px;
  width: fit-content;
}


#WarnAddButton{
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: black;
  color: white;
  cursor: pointer;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate{
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  
}

.WarnTitle{
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
  
  
}

#ButtonContainer{
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 15px;
  margin-top: 10px;
  margin-left: 73%;
}

#deleteButtonWarnings{
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom:20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  
}
#deleteButtonWarnings:hover{
  
  background-color: rgb(255, 64, 64);
 
}

#EditButton{
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom:20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover{
  background-color: #1d6995;
}
