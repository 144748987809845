* {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
}

body {
  background: #fcfcfc;
}

.container {
  width: 100%;
  height: 100vh;
}
.dropdown {
  width: 320px;
  user-select: none;
  margin-top: 25px;
  margin-left: 20px;
  position: relative;
}
.dropdown .dropdown-btn {
  background-color: #f0f0f0;
  padding-left: 30px;
  font-size: 1em;
  width: 300px;
  height: 40px;
  border-radius: 15px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;

  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  color: "#424242";
}

.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: #333;
  width: 93%;
  z-index: 1;
}
.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item:hover {
  background: #f4f4f4;
}

.chevron {
  margin-right: 10px;
}
