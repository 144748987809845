.WarnDate {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
}

.WarnTitle {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.boldFields {
  font-weight: 600;
}

.confirmButton {
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
  height: 4.5vh;
  background-color: #00334e;
  color: white;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 2.5vh;
}

.inputField {
  margin-bottom: 1.5vh;
  padding-left: 4%;
  width: 100%;
  height: 6vh;
  border-radius: 10px;
  font-size: 2.5vh;
  border-width: 0.5;
  margin-bottom: 3vh;
}
