#managementCenter {
  box-sizing: border-box;
  width: 100%;
  padding-left: 440px;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.managementOptionsWrap {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 0px;
  box-sizing: border-box;
  align-items: center;
  border-right: 1px solid rgb(231, 231, 231);
}
.mngm-opt-title {
  display: flex;
  align-items: center;
  width: 80%;
  gap: 40px;
}

.mngm-opt-return-img {
  height: 80px;
  transform: rotate(180deg);
}
.managementOptionsWrap > div > p {
  font-size: 48px;
}

.managementModalWrap {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.managementOptionsContainer {
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 100px;
  width: 80%;
  align-items: center;
}

.managementOptionsContainer > button {
  width: 300px;
  height: 70px;
  color: white;
  background-color: #00334E;
  border-style: none;
  border-radius: 16px;
  font-size: 18px;
  transition: ease-in;
}

.managementOptionsContainer > button:hover {
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 50%);
  background-color: #EE6F57;
}

.managementModalContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.managementModalContainer > h1 {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.mngm-content {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 5%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.mngm-add-manutencao {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 50px;
  width: fit-content;
  
  background-color: #EE6F57;
  border-radius: 8px;
  font-weight: bold;
}

.mngm-add-manutencao:hover {
  cursor: pointer;
  background-color: #EE6F57;
}

.mngm-add-manutencao > img {
  height: 30px;
}

.mngm-mntc-eqp-title {
  width: 100%;
}

.mngm-mntc-eqp-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mngm-mntc-eqp-item {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 24px 24px;
  box-sizing: border-box;
  height: 80px;
  border: 1px solid black;
  border-radius: 16px;
}
/*branch main*/

.pageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 650px;
}

#pageTitle {
  padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

#formArea {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-left: 40%;
}

.registerInput {
  height: 120px;
  width: 415px;
  background-color: #efefef;
  margin: 0px 30px 40px 70px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registerInputTitle {
  padding: 15px 0px 15px 0px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

#registerInputText {
  margin-left: 20px;
  width: 340px;
}

#signUpCondominium {
  top: 710px;
  left: 50px;
  border: none;
  width: 150px;
}

#signUpCondominiumText {
  padding-left: 15px;
  padding-right: 15px;
}

#signUpCondominium:hover {
  background: rgb(72, 219, 251);
}

#signUpCondominium:focus {
  background: rgb(54, 153, 175);
}

#loadingBox {
  margin-left: 40px;
}

#errorMessage2 {
  font-family: "Montserrat", sans-serif;
  padding-left: 26px;
  font-size: 14px;
}

#content {
  margin-left: 500px;
}

#picker {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: 500px;
}

#link {
  margin-left: 30px;
  margin-top: 40px;
}

