#esqueciContainer {
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 1.5em rgb(0, 0, 0, 0.5);
  border-radius: 10px;

  width: 450px;
  padding: 48px;
  animation: fadeIn 2s;
}

#esqueciMinorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#esqueciTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 56px;

  color: #000000;

  transition: 0.25s;
}

#esqueciText {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #000000;

  transition: 0.25s;

  margin-bottom: 10%;
}

#sendEmail {
  border: none;
}

#sendEmail:hover {
  border: none;
  background: #EE6F57;
}

#sendEmail:focus {
  background: rgb(54, 153, 175);
}

#esqueciDec {
  display: flex;
  justify-content: center;

  width: 100%;

  height: 10px;
  background: rgb(72, 219, 251);

  margin-top: 10%;
  padding-bottom: 18%;
  border-radius: 0px 0px 10px 10px;
}

#signInBack2 {
  margin-top: 20px;
  background-color: transparent;
  color: white;
  border-color: white;
}

#signInBack2:hover {
  background-color: white;
  color: black;
  border-color: white;
}

.forgotPassForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}
