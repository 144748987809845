.spacePageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  align-items: center;
}

.pageTitleContainer{
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  

}

.pageTitle {
  padding-left: 2rem;
  font-size: 2.25rem;
  line-height: 5.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
}

.formikFieldArea {
  padding: 10px 20px 5px 20px;
  height: 120px;
  width: 80%;
  background-color: #efefef;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.formikFieldLabel {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-left: 0.5;
  cursor: text;
  overflow: hidden;
  width: auto;
  margin-bottom: 0.75rem;
}

.formikFieldText {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: calc(100% - 2rem);
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikErrorMessage {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
  cursor: text;
  color: #fa541c;
  margin-left: 1rem;
}

.formikSubmitButton {
  background-color: rgb(221, 221, 221);
  border: 2.23px solid #000000;
  box-sizing: border-box;
  border-radius: 50px;
  width: 155px;
  height: 50px;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 23px;
  transition: 0.25s;
  position: relative;
  transition-duration: 0.3s;
  transform: scale(0.95);
}

.formikSubmitButton:hover {
  background: rgba(72, 219, 251, 0.3);
}

.formikSubmitButton:focus {
  background: rgba(54, 153, 175, 0.3);
}

.formikSubmitButtonText {
  padding-left: 15px;
  padding-right: 15px;
}

.ml4 {
  margin-left: 4.1rem;
}

.infoLabel {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.dataContainer-lg {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  height: 24.5rem;
  padding: 2rem;
  margin: 1rem 1rem 1rem 1rem;
}

.actionButton {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.actionButton.delete:hover {
  background-color: #e85d04;
}
.actionButton.edit:hover {
  background-color: rgba(72, 219, 251, 0.3);
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 0px 40px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}
