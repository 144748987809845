#esqueciContainer {
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 1.5em rgb(0, 0, 0, 0.5);
  border-radius: 10px;

  width: 450px;

  animation: fadeIn 2s;
}

#esqueciMinorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#esqueciMinorContainer > a {
  width: 100%;
}

#esqueciTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 56px;

  color: #000000;

  transition: 0.25s;
}

#esqueciText {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #000000;

  transition: 0.25s;

  margin-bottom: 10%;
}

#sendNewPass {
  border: none;
  width: 200px;
}

#sendNewPass:hover {
  border: none;
  background: rgb(72, 219, 251);
}

#sendNewPass:focus {
  background: rgb(54, 153, 175);
}

#signInBack2 {
  margin-top: 20px;
  width: 100%;
  background-color: transparent;
  color: #dddddd;
  padding: 8px 24px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  font-size: 16px;
}

#signInBack2:hover {
  background-color: white;
  color: black;
  border-color: black;
  cursor: pointer;
}
