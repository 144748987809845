.TitleContainer2 {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  width: 650px;
}

#x2 {
  margin-left: 5vw;
}

.Title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#listArea2 {
  margin-left: 5vw;
}

.properties-data-safe-area {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.properties-photos-list {
  display: flex;
}
.properties-photos-list a {
  display: flex;
  text-decoration: none;
}

.properties-photos-list p:last-of-type {
  content: none;
}
.properties-btn-wrapp {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  gap: 18px;
}

.properties-btn-wrapp button {
  padding: 12px 24px;
  border-radius: 24px;
  border: 1px solid #dfdfdf;
}
.properties-btn-wrapp button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.properties-btn-wrapp #deleteButton {
  margin: 0;
}

#propertiesApproveButton {
}
#propertiesApproveButton {
}
