.backButton{
  border: none;
  background-color: transparent;
}

#approveButton{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    position: absolute;
   
    margin-left: 500px;
    margin-top: 65px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
  }

  #approveButton:hover{
  
    background-color: rgb(56, 216, 51);;
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    
    margin-left: 86%;
    margin-top:70px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
    
   
  }

  #cross3{
    width:14px;
    height: 2px;
    margin-right: 13px;
    margin-top: 5px;
    background: black;
    border-radius: 15px;
    position: absolute;
    transform: rotate(50deg);
    transition: 0.5s;
  }
  
  #cross4{
    width:27px;
    height: 2px;
    margin-left: 15px;
    background: black;
    border-radius: 15px;
    position: absolute;
    transition: 0.5s;
    transform: rotate(-47.5deg);
  }
  #deleteButtonResident{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    
    margin-left: 86%;
    margin-top:70px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
    
  }
  #deleteButtonResident:hover{
    
    background-color: rgb(255, 64, 64);;
   
  }

  