#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

.radio-input-wrap label:first-child {
  margin-top: 32px;
  margin-bottom: 32px;
}

.radio-input-wrap label:last-child {
  margin-bottom: 20px;
}

.radio-input {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.radio-input input {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.radio-input svg {
  font-size: 24px;
}
