#sidebarContainer {
  height: 100vh;
  background: #00334E;
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  top: 0;
  left: 0;
  word-break: keep-all;
  overflow-x: hidden;
  position: fixed;
}

#bar {
  height: 1px;
  background-color: white;
  margin-left: 69px;
  margin-right: 69px;
}

#space {
  height: 40px;
}

#sidebarTopInfo {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 24px 40px;
}
#topInfo {
  padding-left: 19.5px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: white;
}
#usernameText {
  margin: 5px;
  margin-bottom: 8px;
  
}
#jobText {
  margin: 5px;
  margin-bottom: 8px;
  font-weight: 800;
}
#profileText {
  margin: 5px;
  margin-top: 10px;
  cursor: pointer;
  color: white;
}
#profileText:hover {
 text-decoration: underline;
}
#sidebarMidInfo {
  box-sizing: border-box;
  padding: 24px 40px;
}
#sidebarBottomInfo {
  box-sizing: border-box;
  padding: 50px 40px;
  color: white;
}

#pic {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

#logo {
  height: auto;
  width: 100%;
  align-self: center;
}
