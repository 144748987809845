.idLabel {
  font-size: 1.25rem;
  font-weight: 600;
  font-style: normal;
}

.idData {
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
}

.idWarning {
  font-size: 2rem;
  font-weight: 500;
  font-style: normal;
  color: #ff6767;
}

.idLoading {
  font-size: 1.5rem;
  font-weight: 400;
  font-style: normal;
}
