@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

* {
  padding: 0;
  margin: 0%;
}

#root,
html,
body {
  height: 100%;
  width: 100%;
}

.all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#mainContainer {
  display: flex;
  flex-direction: row;

  box-shadow: 0 0 1.5em rgb(0, 0, 0, 0.15);
  border-radius: 10px;

  width: 940px;
  height: 520px;

  animation: fadeIn 2s;
}

#leftContainer {
  background-image: url("../../../Assets/4931029.jpg");
  background-color: #00334e;
  box-shadow: inset 0 0 0 1000px #00334eea;
  background-repeat: no-repeat, repeat;
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
  width: 470px;
}

#teste {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
  margin: 32% 9% 20% 9%;
}

.welcomeTitle {
  font-size: 36px;
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
}

.divider {
  background-color: #fff;
  width: 100px;
  height: 4px;
  border-radius: 20px;
}

.welcomeText {
  color: rgba(255, 255, 255, 0.658);
  font-family: "DM Sans ", sans-serif;
  font-weight: lighter;
  width: 300px;
  font-size: 17px;
}

#signUp {
  margin-top: 10%;
  background-color: transparent;
  color: white;
  border-color: white;
  width: 200px;

  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#rightContainer {
  width: 470px;
  height: 520px;
  padding: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
}

#rightContainer form {
  width: 100%;
}

#loginText {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 42.5646px;

  color: #00334e;

  transition: 0.25s;
}

#inputs {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.inputBox {
  width: 100%;
}

.inputText {
  width: 100%;
  text-align: start;

  border-radius: 4px;
  border: none;
  border-left: 4px solid #00334e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  background-color: #f8f8f8;

  padding: 12px 24px;
  outline: none;
  transition: 0.25s;
}

.handleButton {
  background-color: #00334e;
  color: #fff;
  border-radius: 50px;
  border: none;

  box-sizing: border-box;

  width: 100%;
  padding: 8px 0;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  transition: 0.2s;
  margin-top: 36px;
  position: relative;
}

#esqueci {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.handleButton:hover {
  background: #ee6f57;
}

#signUp:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

#signUp:focus {
  background: rgb(219, 219, 219);
}

#esqueci:hover {
  text-decoration: underline;
}

.errorMessage {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  color: red;
  visibility: hidden;
}

.erroActive {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  color: red;
  visibility: visible;
}
