.react-select-container {
  width: calc(100% - 2rem);
}

.react-select__control {
  background-color: rgba(72, 219, 251, 0.3) !important;
  border-radius: 30px !important;
  padding: 0.1rem;
  border: none;
}

.react-select__multi-value {
  background-color: rgba(72, 219, 251, 0.4) !important;
  border-radius: 30px !important;
  padding: 0.2rem;
}

.react-select__menu-list {
  background-color: rgba(72, 219, 251, 0.3) !important;
}
.react-select__option:hover {
  background-color: rgba(72, 219, 251, 0.4) !important;
}

.react-select__indicator {
  color: hsl(0, 0%, 60%) !important;
}

.formikFieldSelectFile {
  background-color: rgba(72, 219, 251, 0.3);
  text-align: center;
  width: calc(100% - 2rem);
  border: none;
  border-radius: 30px;
  height: 2rem;
  padding: 0.4rem;
}
