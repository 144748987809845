  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
    
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    color: #fff;
    margin-top: 10px;
    
    border-radius: 5px;
  }
  #logoprofile {
    height: 250px;
    width: 250px;
    border-radius: 100%;
  }

  .clearButton {
    background-color: rgb(255, 64, 64);
    color: #fff;
    border-radius: 50px;
    border: none;
  
    box-sizing: border-box;
  
    width: 100%;
    padding: 8px 0;
    cursor: pointer;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 17px;
    transition: 0.2s;
    margin-top: 36px;
    position: relative;
  }

  .clearButton:hover {
    background: black;
  }

  #ImgPerfil{
    height: 250px;
    width: 250px;
    border-radius: 50%;
  }

#DivImgPerfil{
  align-items: center;
}
#picPerfil {
  height: 250px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
#logoPerfil {
  height: auto;
  width: 100%;
  align-self: center;
}