@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap);
html{
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#loadingContainer {
  height: 38px;
  width: 38px;
  margin-top: 36px;
  color: #00334E;
}

* {
  padding: 0;
  margin: 0%;
}

#root,
html,
body {
  height: 100%;
  width: 100%;
}

.all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#mainContainer {
  display: flex;
  flex-direction: row;

  box-shadow: 0 0 1.5em rgb(0, 0, 0, 0.15);
  border-radius: 10px;

  width: 940px;
  height: 520px;

  -webkit-animation: fadeIn 2s;

          animation: fadeIn 2s;
}

#leftContainer {
  background-image: url(/static/media/4931029.6183dad5.jpg);
  background-color: #00334e;
  box-shadow: inset 0 0 0 1000px #00334eea;
  background-repeat: no-repeat, repeat;
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
  width: 470px;
}

#teste {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 36px;
  gap: 36px;
  margin: 32% 9% 20% 9%;
}

.welcomeTitle {
  font-size: 36px;
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
}

.divider {
  background-color: #fff;
  width: 100px;
  height: 4px;
  border-radius: 20px;
}

.welcomeText {
  color: rgba(255, 255, 255, 0.658);
  font-family: "DM Sans ", sans-serif;
  font-weight: lighter;
  width: 300px;
  font-size: 17px;
}

#signUp {
  margin-top: 10%;
  background-color: transparent;
  color: white;
  border-color: white;
  width: 200px;

  text-decoration: none;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#rightContainer {
  width: 470px;
  height: 520px;
  padding: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 36px;
  gap: 36px;
}

#rightContainer form {
  width: 100%;
}

#loginText {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 42.5646px;

  color: #00334e;

  transition: 0.25s;
}

#inputs {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.inputBox {
  width: 100%;
}

.inputText {
  width: 100%;
  text-align: start;

  border-radius: 4px;
  border: none;
  border-left: 4px solid #00334e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  background-color: #f8f8f8;

  padding: 12px 24px;
  outline: none;
  transition: 0.25s;
}

.handleButton {
  background-color: #00334e;
  color: #fff;
  border-radius: 50px;
  border: none;

  box-sizing: border-box;

  width: 100%;
  padding: 8px 0;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  transition: 0.2s;
  margin-top: 36px;
  position: relative;
}

#esqueci {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.handleButton:hover {
  background: #ee6f57;
}

#signUp:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

#signUp:focus {
  background: rgb(219, 219, 219);
}

#esqueci:hover {
  text-decoration: underline;
}

.errorMessage {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  color: red;
  visibility: hidden;
}

.erroActive {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  color: red;
  visibility: visible;
}

#esqueciContainer {
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 1.5em rgb(0, 0, 0, 0.5);
  border-radius: 10px;

  width: 450px;
  padding: 48px;
  -webkit-animation: fadeIn 2s;
          animation: fadeIn 2s;
}

#esqueciMinorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#esqueciTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 56px;

  color: #000000;

  transition: 0.25s;
}

#esqueciText {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #000000;

  transition: 0.25s;

  margin-bottom: 10%;
}

#sendEmail {
  border: none;
}

#sendEmail:hover {
  border: none;
  background: #EE6F57;
}

#sendEmail:focus {
  background: rgb(54, 153, 175);
}

#esqueciDec {
  display: flex;
  justify-content: center;

  width: 100%;

  height: 10px;
  background: rgb(72, 219, 251);

  margin-top: 10%;
  padding-bottom: 18%;
  border-radius: 0px 0px 10px 10px;
}

#signInBack2 {
  margin-top: 20px;
  background-color: transparent;
  color: white;
  border-color: white;
}

#signInBack2:hover {
  background-color: white;
  color: black;
  border-color: white;
}

.forgotPassForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

#esqueciContainer {
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 1.5em rgb(0, 0, 0, 0.5);
  border-radius: 10px;

  width: 450px;

  -webkit-animation: fadeIn 2s;

          animation: fadeIn 2s;
}

#esqueciMinorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#esqueciMinorContainer > a {
  width: 100%;
}

#esqueciTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 56px;

  color: #000000;

  transition: 0.25s;
}

#esqueciText {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #000000;

  transition: 0.25s;

  margin-bottom: 10%;
}

#sendNewPass {
  border: none;
  width: 200px;
}

#sendNewPass:hover {
  border: none;
  background: rgb(72, 219, 251);
}

#sendNewPass:focus {
  background: rgb(54, 153, 175);
}

#signInBack2 {
  margin-top: 20px;
  width: 100%;
  background-color: transparent;
  color: #dddddd;
  padding: 8px 24px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  font-size: 16px;
}

#signInBack2:hover {
  background-color: white;
  color: black;
  border-color: black;
  cursor: pointer;
}

.idLabel {
  font-size: 1.25rem;
  font-weight: 600;
  font-style: normal;
}

.idData {
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
}

.idWarning {
  font-size: 2rem;
  font-weight: 500;
  font-style: normal;
  color: #ff6767;
}

.idLoading {
  font-size: 1.5rem;
  font-weight: 400;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0%;
}

#root,
html,
body {
  height: 100%;
  width: 100%;
}

.all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#mainContainerCad {
  display: flex;
  flex-direction: row;

  box-shadow: 0 0 1.5em rgb(0, 0, 0, 0.15);
  border-radius: 10px;

  width: 80vw;
  height: 80vh;

  -webkit-animation: fadeIn 2s;

          animation: fadeIn 2s;
}

#leftContainerCad {
  background-image: url(/static/media/4931029.6183dad5.jpg);
  background-color: #00334e;
  box-shadow: inset 0 0 0 1000px #00334eea;
  background-repeat: no-repeat, repeat;
  border-radius: 10px 0px 0px 10px;
  background-size: cover;
  flex: 1 1;
  width: 100%;
}

#teste2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 36px;
  gap: 36px;
  margin: 0% 9% 0% 9%;
}

.welcomeTitle {
  font-size: 36px;
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
}

.divider {
  background-color: #fff;
  width: 100px;
  height: 4px;
  border-radius: 20px;
}

.welcomeText {
  color: rgba(255, 255, 255, 0.658);
  font-family: "DM Sans ", sans-serif;
  font-weight: lighter;
  width: 300px;
  font-size: 17px;
}

#signUp {
  margin-top: 10%;
  background-color: transparent;
  color: white;
  border-color: white;
  width: 200px;

  text-decoration: none;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#rightContainerCad {
  flex: 1 1;
  width: 100%;
  height: 100%;
  padding: 35px 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#rightContainer form {
  width: 100%;
}

#loginText {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 42.5646px;

  color: #00334e;

  transition: 0.25s;
}

#inputs {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.inputBox {
  width: 95%;
}
.inputBox2 {
  width: 100%;
  display: flex;
  align-items: center;
}

.inputText {
  width: 100%;
  text-align: start;

  border-radius: 4px;
  border: none;
  border-left: 4px solid #00334e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  background-color: #f8f8f8;

  padding: 12px 24px;
  outline: none;
  transition: 0.25s;
}

.handleButton {
  background-color: #00334e;
  color: #fff;
  border-radius: 50px;
  border: none;

  box-sizing: border-box;

  width: 100%;
  padding: 8px 0;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  transition: 0.2s;
  margin-top: 36px;
  position: relative;
}

#esqueci {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.handleButton:hover {
  background: #ee6f57;
}

#signUp:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

#signUp:focus {
  background: rgb(219, 219, 219);
}

#esqueci:hover {
  text-decoration: underline;
}

.errorMessage {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  color: red;
  visibility: hidden;
}

.erroActive {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  color: red;
  visibility: visible;
}

.radio-group {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
}

.radio-label {
  display: flex;
  margin-bottom: 10px;
}

.radio-input {
  margin-right: 5px;
}

#homeContent {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

#homeWelcome {
  padding-left: 60px;
  padding-top: 3%;
  width: 1200px;
}

#homeCentera {
  padding-top: 4vh;
  padding-left: 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3%;
  gap: 3%;
  box-sizing: border-box;
  width: 100%;
  padding-right: 2vw;
  grid-template-rows: repeat(4, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 4vh;
}

#plusIcon {
  width: 55px;
  height: 55px;
  margin-right: 10px;
}

#option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0px 0px 50px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  width: 350px;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}

#sidebarContainer {
  height: 100vh;
  background: #00334E;
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  top: 0;
  left: 0;
  word-break: keep-all;
  overflow-x: hidden;
  position: fixed;
}

#bar {
  height: 1px;
  background-color: white;
  margin-left: 69px;
  margin-right: 69px;
}

#space {
  height: 40px;
}

#sidebarTopInfo {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 24px 40px;
}
#topInfo {
  padding-left: 19.5px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: white;
}
#usernameText {
  margin: 5px;
  margin-bottom: 8px;
  
}
#jobText {
  margin: 5px;
  margin-bottom: 8px;
  font-weight: 800;
}
#profileText {
  margin: 5px;
  margin-top: 10px;
  cursor: pointer;
  color: white;
}
#profileText:hover {
 text-decoration: underline;
}
#sidebarMidInfo {
  box-sizing: border-box;
  padding: 24px 40px;
}
#sidebarBottomInfo {
  box-sizing: border-box;
  padding: 50px 40px;
  color: white;
}

#pic {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

#logo {
  height: auto;
  width: 100%;
  align-self: center;
}

#circle {
  height: 65px;
  width: 65px;
  background-color: #00334e;
  border-radius: 100%;
  display: flex;
  padding-top: 20px;
  align-items: center;

  transition: 0.5s;
  cursor: pointer;
}

#circle:hover {
  background: #ee6f57;
  transition: 0.5s;
}

ul li {
  list-style: none;
  padding-left: 25px;

  transition: 0.5s;
}

ul li:hover {
  transition: 0.5s;
  padding-left: 20px;
}

ul li span {
  display: flex;
  width: 20px;
  height: 20px;
  border: 4px solid #ffff;
  transition: 0.5s;

  -webkit-transform: translateY(-50%) rotate(-45deg);

          transform: translateY(-50%) rotate(-45deg);

  border-bottom: none;
  border-right: none;
}

ul li:hover span {
  width: 20px;
  height: 20px;
  border: 4px solid #ffff;
  transition: 0.5s;

  -webkit-transform: translateY(-50%) rotate(-45deg);

          transform: translateY(-50%) rotate(-45deg);

  border-bottom: none;
  border-right: none;
}

ul li span:before {
  width: 20px;
  height: 4px;
  border-radius: 15px;
  background: #ffff;
  content: "";
  margin-top: 10px;
  margin-right: 55px;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: rotate(45deg) translate(-2px, -1px) scale(0);
          transform: rotate(45deg) translate(-2px, -1px) scale(0);
  transition: 0.5s;
  border-bottom: none;
  border-right: none;
}

ul li:hover span:before {
  -webkit-transform: rotate(45deg) translate(-2px, -1px) scaleX(1);
          transform: rotate(45deg) translate(-2px, -1px) scaleX(1);
}

  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
    
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    color: #fff;
    margin-top: 10px;
    
    border-radius: 5px;
  }
  #logoprofile {
    height: 250px;
    width: 250px;
    border-radius: 100%;
  }
  #picPerfil {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  #logoPerfil {
    height: auto;
    width: 100%;
    align-self: center;
  }
#listArea {
  height: 100%;
  width: 100%;

  margin-left: 88%;
  margin-top: 10%;

  flex-direction: column;
  flex-wrap: wrap;
}

#x {
  margin-left: 85%;
}

#infoNames {
  display: flex;
  flex-direction: row;
  padding: 25px 20px 10px 20px;
}

#infoTitle {
  margin: 10px 20px 5px;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.data-container {
  max-width: 600px;
  width: 90%;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 22px;
  margin-bottom: 30px;
  word-break: break-all;
  transition: 0.25s;
}

.data-container:hover {
  border-left: 5px solid #00334e;
}

#data-safe-area {
  margin: 20px;
}

.condominium-name-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.manager-name-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}
.state-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}
.cep-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.unities-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#deleteButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;

  margin-left: 86%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#deleteButton:hover {
  background-color: rgb(255, 64, 64);
}

#editButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;

  margin-left: 86%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#editButton:hover {
  background-color: rgb(64, 255, 80);
}

#blockButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;

  margin-left: 0;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#blockButton:hover {
  background-color: #1d6995;
}

#cross1 {
  width: 27px;
  height: 2px;
  background: black;
  border-radius: 15px;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

#cross2 {
  width: 27px;
  height: 2px;
  background: black;
  border-radius: 15px;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

#searchInput {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-left: 15px;
  outline: none;
}

#searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: none;
  height: 50px;
  width: 270px;
  text-align: start;

  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  background-color: #f6f5f5;
  border: none;
  padding-left: 20px;
  outline: none;
  transition: 0.25s;
}

.backButton{
  border: none;
  background-color: transparent;
}

#approveButton{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    position: absolute;
   
    margin-left: 500px;
    margin-top: 65px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
  }

  #approveButton:hover{
  
    background-color: rgb(56, 216, 51);;
   
  }

  #cross3{
    width:14px;
    height: 2px;
    margin-right: 13px;
    margin-top: 5px;
    background: black;
    border-radius: 15px;
    position: absolute;
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
    transition: 0.5s;
  }
  
  #cross4{
    width:27px;
    height: 2px;
    margin-left: 15px;
    background: black;
    border-radius: 15px;
    position: absolute;
    transition: 0.5s;
    -webkit-transform: rotate(-47.5deg);
            transform: rotate(-47.5deg);
  }
* {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
}

body {
  background: #fcfcfc;
}

.container {
  width: 100%;
  height: 100vh;
}
.dropdown {
  width: 320px;
  -webkit-user-select: none;
          user-select: none;
  margin-top: 25px;
  margin-left: 20px;
  position: relative;
}
.dropdown .dropdown-btn {
  background-color: #f0f0f0;
  padding-left: 30px;
  font-size: 1em;
  width: 300px;
  height: 40px;
  border-radius: 15px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;

  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  color: "#424242";
}

.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: #333;
  width: 93%;
  z-index: 1;
}
.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item:hover {
  background: #f4f4f4;
}

.chevron {
  margin-right: 10px;
}

.ta{
}
.pageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 650px;
}

.pageTitleContainerx {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 650px;
  
}

.propagandaCheckbox {
  height: 12px;
  width: 12px;
  border: none;
  color: yellow;
  border-radius: 4px;
}

#pageTitle {
  padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

#formArea {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-left: 40%;
}

.registerInput {
  height: 120px;
  width: 415px;
  background-color: #efefef;
  margin: 0px 16px 8px 0px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registerInputTitle {
  padding: 15px 0px 15px 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

#registerInputText {
  margin-left: 20px;
  width: 340px;
}

#signUpCondominium {
  top: 710px;
  left: 50px;
  border: none;
  width: 150px;
}

#signUpCondominiumText {
  padding-left: 15px;
  padding-right: 15px;
}

#signUpCondominium:hover {
  background: rgb(72, 219, 251);
}

#signUpCondominium:focus {
  background: rgb(54, 153, 175);
}

#loadingBox {
  margin-left: 40px;
}

#errorMessage2 {
  font-family: "Montserrat", sans-serif;
  padding-left: 26px;
  font-size: 14px;
}

#content {
  margin-left: 500px;
  margin-bottom: 20px;
}

#filePicker {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

#advertButton {
  width: 300px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid black;
  background-color: black;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

#states {
  padding: 15px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
}

#stateOption {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

#column {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

#inputArea {
  height: 100%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: left;
}

#propagandaInput {
  font-size: 15px;
}

#propContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 35vw;
}

.InputProp {
  border: 1px solid black;
  width: 400px;
  border-radius: 10px;
}

.image-erro {
  color: red;
}
  .TitleContainer {
    font-size: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin: 40px 0px 30px 75px;
    display: flex;
    flex-direction: column;
    margin-left: 45%;
    width: 650px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

  .TitleContainer {
    font-size: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin: 40px 0px 30px 75px;
    display: flex;
    flex-direction: column;
    margin-left: 45%;
    width: 650px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
.WarnDate{
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  
}

.WarnTitle{
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  
  
}
.WarnDate{
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  
}

.WarnTitle{
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  
  
}
#TopContainer1 {
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#WarnAddButton {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: black;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
}

.WarnTitle {
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
}

#ButtonContainer {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 73%;
}

#ButtonContainer2 {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 63%;
}

#deleteButtonWarnings {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#deleteButtonWarnings:hover {
  background-color: rgb(255, 64, 64);
}

#EditButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-left: 10px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover {
  background-color: #1d6995;
}

    .botaoEnviar{
        background-color: #1E90FF;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 3px;
        margin-right: 8px;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }
    
#x {
  margin-left: 26vw;
}

#listArea {
  margin-left: 26vw;
  grid-gap: 10px;
  gap: 10px;
}

#marginTop {
  margin-top: 10px;
}

#divbutton {
}

#homeContent {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

#homeWelcome {
  padding-left: 60px;
  padding-top: 3%;
  width: 1200px;
}

#homeCenter {
  padding-top: 58px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 50px;
  grid-template-rows: repeat(4, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 58px;
}

#plusIcon {
  width: 55px;
  height: 55px;
  margin-right: 10px;
}

#option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0px 0px 50px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  width: 350px;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}

.TitleContainer2 {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  width: 650px;
}

#x2 {
  margin-left: 5vw;
}

.Title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#listArea2 {
  margin-left: 5vw;
}

.properties-data-safe-area {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.properties-photos-list {
  display: flex;
}
.properties-photos-list a {
  display: flex;
  text-decoration: none;
}

.properties-photos-list p:last-of-type {
  content: none;
}
.properties-btn-wrapp {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  grid-gap: 18px;
  gap: 18px;
}

.properties-btn-wrapp button {
  padding: 12px 24px;
  border-radius: 24px;
  border: 1px solid #dfdfdf;
}
.properties-btn-wrapp button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.properties-btn-wrapp #deleteButton {
  margin: 0;
}

#propertiesApproveButton {
}
#propertiesApproveButton {
}

  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
    
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    color: #fff;
    margin-top: 10px;
    
    border-radius: 5px;
  }
  #logoprofile {
    height: 250px;
    width: 250px;
    border-radius: 100%;
  }
  #picPerfil {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  #logoPerfil {
    height: auto;
    width: 100%;
    align-self: center;
  }
#homeCenter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 50px;
  padding-left: 490px;
  grid-template-rows: repeat(4, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 58px;
}

#home-card {
  border-radius: 21px;
  box-shadow: 0px 2px 4px -1px #00000012;
  box-shadow: 0px 4px 6px -1px #0000001f;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: 200ms;
}

#home-card h1 {
  text-align: center;
}

#home-card:hover {
  box-shadow: 0px 4px 10px -1px #0000009a;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}

#home-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#home-card:nth-child(4) img {
  object-fit: contain;
}

#home-card:nth-child(5) img {
  object-fit: contain;
}

#home-card:nth-child(11) img {
  object-fit: contain;
}

#home-card:nth-child(8) img {
  object-fit: contain;
}
#home-card:nth-child(9) img {
  object-fit: contain;
}

#home-card:nth-child(12) img {
  object-fit: contain;
}

#TopContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 135px;
  gap: 135px;
  align-items: flex-start;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#WarnAddButton {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: black;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  margin-bottom: 20px;
}

.WarnTitle {
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
}

#ButtonContainer {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 73%;
}

#deleteButtonWarnings {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#deleteButtonWarnings:hover {
  background-color: rgb(255, 64, 64);
}

#EditButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover {
  background-color: #1d6995;
}

#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background:#EE6F57;
}

#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

.radio-input-wrap label:first-child {
  margin-top: 32px;
  margin-bottom: 32px;
}

.radio-input-wrap label:last-child {
  margin-bottom: 20px;
}

.radio-input {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.radio-input input { /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.radio-input svg {
  font-size: 24px;
}

.TitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  flex-direction: column;
  margin-left: 45%;
  width: 650px;
}

.Title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
    
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    color: #fff;
    margin-top: 10px;
    
    border-radius: 5px;
  }
  #logoprofile {
    height: 250px;
    width: 250px;
    border-radius: 100%;
  }

  .clearButton {
    background-color: rgb(255, 64, 64);
    color: #fff;
    border-radius: 50px;
    border: none;
  
    box-sizing: border-box;
  
    width: 100%;
    padding: 8px 0;
    cursor: pointer;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 17px;
    transition: 0.2s;
    margin-top: 36px;
    position: relative;
  }

  .clearButton:hover {
    background: black;
  }

  #ImgPerfil{
    height: 250px;
    width: 250px;
    border-radius: 50%;
  }

#DivImgPerfil{
  align-items: center;
}
#picPerfil {
  height: 250px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
#logoPerfil {
  height: auto;
  width: 100%;
  align-self: center;
}
#homeCenter {
  padding-top: 4vh;
  padding-left: 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3%;
  gap: 3%;
  box-sizing: border-box;
  width: 100%;
  padding-right: 2vw;
  grid-template-rows: repeat(4, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 4vh;
}

#home-card {
  border-radius: 21px;
  box-shadow: 0px 2px 4px -1px #00000012;
  box-shadow: 0px 4px 6px -1px #0000001f;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: 200ms;
}

#home-card:hover {
  box-shadow: 0px 4px 10px -1px #0000009a;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}

#home-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#home-card:nth-child(4) img {
  object-fit: contain;
}

#home-card:nth-child(5) img {
  object-fit: contain;
}

#home-card:nth-child(11) img {
  object-fit: contain;
}

#home-card:nth-child(8) img {
  object-fit: contain;
}
#home-card:nth-child(9) img {
  object-fit: contain;
}

#home-card:nth-child(12) img {
  object-fit: contain;
}

#managementCenter {
  box-sizing: border-box;
  width: 100%;
  padding-left: 440px;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.managementOptionsWrap {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 0px;
  box-sizing: border-box;
  align-items: center;
  border-right: 1px solid rgb(231, 231, 231);
}
.mngm-opt-title {
  display: flex;
  align-items: center;
  width: 80%;
  grid-gap: 40px;
  gap: 40px;
}

.mngm-opt-return-img {
  height: 80px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.managementOptionsWrap > div > p {
  font-size: 48px;
}

.managementModalWrap {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.managementOptionsContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 80px;
  gap: 80px;
  margin-top: 100px;
  width: 80%;
  align-items: center;
}

.managementOptionsContainer > button {
  width: 300px;
  height: 70px;
  color: white;
  background-color: #00334E;
  border-style: none;
  border-radius: 16px;
  font-size: 18px;
  transition: ease-in;
}

.managementOptionsContainer > button:hover {
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 50%);
  background-color: #EE6F57;
}

.managementModalContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.managementModalContainer > h1 {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.mngm-content {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 5%;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.mngm-add-manutencao {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  
  background-color: #EE6F57;
  border-radius: 8px;
  font-weight: bold;
}

.mngm-add-manutencao:hover {
  cursor: pointer;
  background-color: #EE6F57;
}

.mngm-add-manutencao > img {
  height: 30px;
}

.mngm-mntc-eqp-title {
  width: 100%;
}

.mngm-mntc-eqp-list {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.mngm-mntc-eqp-item {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 24px 24px;
  box-sizing: border-box;
  height: 80px;
  border: 1px solid black;
  border-radius: 16px;
}
/*branch main*/

.pageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 650px;
}

#pageTitle {
  padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

#formArea {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-left: 40%;
}

.registerInput {
  height: 120px;
  width: 415px;
  background-color: #efefef;
  margin: 0px 30px 40px 70px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registerInputTitle {
  padding: 15px 0px 15px 0px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

#registerInputText {
  margin-left: 20px;
  width: 340px;
}

#signUpCondominium {
  top: 710px;
  left: 50px;
  border: none;
  width: 150px;
}

#signUpCondominiumText {
  padding-left: 15px;
  padding-right: 15px;
}

#signUpCondominium:hover {
  background: rgb(72, 219, 251);
}

#signUpCondominium:focus {
  background: rgb(54, 153, 175);
}

#loadingBox {
  margin-left: 40px;
}

#errorMessage2 {
  font-family: "Montserrat", sans-serif;
  padding-left: 26px;
  font-size: 14px;
}

#content {
  margin-left: 500px;
}

#picker {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: 500px;
}

#link {
  margin-left: 30px;
  margin-top: 40px;
}


#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

.WarnDate {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
}

.WarnTitle {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.boldFields {
  font-weight: 600;
}

.confirmButton {
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
  height: 4.5vh;
  background-color: #00334e;
  color: white;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 2.5vh;
}

.inputField {
  margin-bottom: 1.5vh;
  padding-left: 4%;
  width: 100%;
  height: 6vh;
  border-radius: 10px;
  font-size: 2.5vh;
  border-width: 0.5;
  margin-bottom: 3vh;
}

#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

.radio-input-wrap-2 {
  display: flex;
  align-items: center;
  grid-gap: 32px;
  gap: 32px;
  margin-bottom: 16px;
}

.radio-input-wrap label:first-child {
}

.radio-input-wrap label:last-child {
}

.radio-input {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.radio-input input { /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.radio-input svg {
  font-size: 24px;
}

  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    margin-top: 10px;
    border-radius: 5px;
    color: #fff;
  }
  #picPerfil {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  #logoPerfil {
    height: auto;
    width: 100%;
    align-self: center;
  }
  

.spacePageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  align-items: center;
}

.pageTitleContainer{
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  

}

.pageTitle {
  padding-left: 2rem;
  font-size: 2.25rem;
  line-height: 5.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
}

.formikFieldArea {
  padding: 10px 20px 5px 20px;
  height: 120px;
  width: 80%;
  background-color: #efefef;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.formikFieldLabel {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-left: 0.5;
  cursor: text;
  overflow: hidden;
  width: auto;
  margin-bottom: 0.75rem;
}

.formikFieldText {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: calc(100% - 2rem);
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikErrorMessage {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
  cursor: text;
  color: #fa541c;
  margin-left: 1rem;
}

.formikSubmitButton {
  background-color: rgb(221, 221, 221);
  border: 2.23px solid #000000;
  box-sizing: border-box;
  border-radius: 50px;
  width: 155px;
  height: 50px;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 23px;
  transition: 0.25s;
  position: relative;
  transition-duration: 0.3s;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.formikSubmitButton:hover {
  background: rgba(72, 219, 251, 0.3);
}

.formikSubmitButton:focus {
  background: rgba(54, 153, 175, 0.3);
}

.formikSubmitButtonText {
  padding-left: 15px;
  padding-right: 15px;
}

.ml4 {
  margin-left: 4.1rem;
}

.infoLabel {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.dataContainer-lg {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  height: 24.5rem;
  padding: 2rem;
  margin: 1rem 1rem 1rem 1rem;
}

.actionButton {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.actionButton.delete:hover {
  background-color: #e85d04;
}
.actionButton.edit:hover {
  background-color: rgba(72, 219, 251, 0.3);
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 0px 40px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

.spacePageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  align-items: center;
}

.pageTitle {
  padding-left: 2rem;
  font-size: 2.25rem;
  line-height: 5.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
}

.formikFieldArea {
  padding: 10px 20px 5px 20px;
  height: 120px;
  width: 90%;
  background-color: #efefef;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.formikFieldLabel {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-left: 0.5;
  cursor: text;
  overflow: hidden;
  width: auto;
  margin-bottom: 0.75rem;
}

.formikFieldText {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: calc(100% - 2rem);
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikFieldText.datePicker {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: 100%;
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikErrorMessage {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
  font-size: 14px;
  cursor: text;
  color: #fa541c;
  margin-left: 1rem;
}

.formikSubmitButton {
  background-color: rgb(221, 221, 221);
  border: 2.23px solid #000000;
  box-sizing: border-box;
  border-radius: 50px;
  width: 155px;
  height: 50px;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 23px;
  transition: 0.25s;
  position: relative;
  transition-duration: 0.3s;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.formikSubmitButton:hover {
  background: rgba(72, 219, 251, 0.3);
}

.formikSubmitButton:focus {
  background: rgba(54, 153, 175, 0.3);
}

.formikSubmitButtonText {
  padding-left: 15px;
  padding-right: 15px;
}

.ml4 {
  margin-left: 4.1rem;
}

.infoLabel {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.dataContainer {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 2rem;
  margin: 1rem 1rem 1rem 1rem;
  
  
}

.dataContainer.lg {
  height: 17rem;
}
.dataContainer.sm {
  height: 9rem;
}

.dataContainer.md {
  height: 16rem;
}

.actionButton {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.actionButton.delete:hover {
  background-color: #e85d04;
}
.actionButton.edit:hover {
  background-color: rgba(72, 219, 251, 0.3);
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 0px 40px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

.spacePageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  align-items: center;
}

.pageTitleContainer {
  display: flex;
  flex-direction: row;
  margin-left: 3%;
}
.pageTitle {
  padding-left: 2rem;
  font-size: 2.25rem;
  line-height: 5.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
}

.fileSelector {
  background-color: rgba(72, 219, 251, 0.3);
  border-radius: 15px;
  cursor: pointer;
  margin: 10px;
  padding: 6px 20px;
  width: 90%;
}

.formikFieldArea {
  padding: 10px 20px 5px 20px;
  height: 170px;
  width: 100%;
  background-color: #efefef;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.formikFieldLabel {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-left: 0.5;
  cursor: text;
  overflow: hidden;
  width: auto;
  margin-bottom: 0.75rem;
}

.formikFieldText {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: calc(100% - 2rem);
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikErrorMessage {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
  cursor: text;
  color: #fa541c;
  margin-left: 1rem;
}

.formikSubmitButton {
  background-color: rgb(221, 221, 221);
  border: 2.23px solid #000000;
  box-sizing: border-box;
  border-radius: 50px;
  width: 155px;
  height: 50px;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 23px;
  transition: 0.25s;
  position: relative;
  transition-duration: 0.3s;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.formikSubmitButton:hover {
  background: rgba(72, 219, 251, 0.3);
}

.formikSubmitButton:focus {
  background: rgba(54, 153, 175, 0.3);
}

.formikSubmitButtonText {
  padding-left: 15px;
  padding-right: 15px;
}

.ml4 {
  margin-left: 4.1rem;
}

.infoLabel {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.archieveText {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.dataContainer {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  
  padding: 1.5rem;
  margin: 1rem 1rem 1rem 1rem;
  overflow: hidden;
}

.actionButton {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.actionButton.delete:hover {
  background-color: #e85d04;
}
.actionButton.edit:hover {
  background-color: rgba(72, 219, 251, 0.3);
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 0px 40px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

.react-select-container {
  width: calc(100% - 2rem);
}

.react-select__control {
  background-color: rgba(72, 219, 251, 0.3) !important;
  border-radius: 30px !important;
  padding: 0.1rem;
  border: none;
}

.react-select__multi-value {
  background-color: rgba(72, 219, 251, 0.4) !important;
  border-radius: 30px !important;
  padding: 0.2rem;
}

.react-select__menu-list {
  background-color: rgba(72, 219, 251, 0.3) !important;
}
.react-select__option:hover {
  background-color: rgba(72, 219, 251, 0.4) !important;
}

.react-select__indicator {
  color: hsl(0, 0%, 60%) !important;
}

.formikFieldSelectFile {
  background-color: rgba(72, 219, 251, 0.3);
  text-align: center;
  width: calc(100% - 2rem);
  border: none;
  border-radius: 30px;
  height: 2rem;
  padding: 0.4rem;
}

#TopContainer1{
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


#WarnAddButton{
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: black;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate{
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  
}

.WarnTitle{
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
  
  
}

#ButtonContainer{
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 73%;
}

#deleteButtonWarnings{
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom:20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  
}
#deleteButtonWarnings:hover{
  
  background-color: rgb(255, 64, 64);
 
}

#EditButton{
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom:20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover{
  background-color: #1d6995;
}

#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

#TopContainer1 {
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#WarnAddButton {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: black;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
}

.WarnTitle {
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
}

#ButtonContainer {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 73%;
}

#deleteButtonWarnings {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#deleteButtonWarnings:hover {
  background-color: rgb(255, 64, 64);
}

#EditButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover {
  background-color: #1d6995;
}

.WarnResendButton {
  display: flex;
  margin-top: 5px;
  border-radius: 0 0 20px 20px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #00334e;
  color: #00334e;
  font-size: 20px;
  transition: 0.5s;
}
.WarnResendButton:hover {
  background-color: #00334e;
  color: white;
  border-radius: 0 0 20px 18px;
}

#TopContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 135px;
  gap: 135px;
  align-items: flex-start;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#WarnAddButton {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #ee6f57;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  margin-right: 50%;
  margin-bottom: 0;
  margin-top: 20px;
}
.WarnDate2 {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
}

.WarnTitle {
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
}

#ButtonContainer {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 0;
  width: 100%;
}

#deleteButtonWarnings {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#deleteButtonWarnings:hover {
  background-color: rgb(255, 64, 64);
}

#EditButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover {
  background-color: #1d6995;
}

.backButton{
  border: none;
  background-color: transparent;
}

#approveButton{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    position: absolute;
   
    margin-left: 500px;
    margin-top: 65px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
  }

  #approveButton:hover{
  
    background-color: rgb(56, 216, 51);;
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    
    margin-left: 86%;
    margin-top:70px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
    
   
  }

  #cross3{
    width:14px;
    height: 2px;
    margin-right: 13px;
    margin-top: 5px;
    background: black;
    border-radius: 15px;
    position: absolute;
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
    transition: 0.5s;
  }
  
  #cross4{
    width:27px;
    height: 2px;
    margin-left: 15px;
    background: black;
    border-radius: 15px;
    position: absolute;
    transition: 0.5s;
    -webkit-transform: rotate(-47.5deg);
            transform: rotate(-47.5deg);
  }
  #deleteButtonResident{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    
    margin-left: 86%;
    margin-top:70px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
    
  }
  #deleteButtonResident:hover{
    
    background-color: rgb(255, 64, 64);;
   
  }

  
#warnContainer {
    display: flex;
    flex-direction: column;
  }
  
  #InputWarnTitle {
    width: 400px;
    border-radius: 10px;
  }
  
  #InputWarnBody {
    width: 400px;
    height: 200px;
    max-width: 600px;
    border-radius: 10px;
    padding-top: 8px;
  }
  
  #InputFile {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  
  #warnBtn {
    border: none;
  }
  
  #warnBtn:hover {
    background: rgb(72, 219, 251);
  }
  
#x {
  margin-left: 26vw;
}

#listArea {
  margin-left: 26vw;
  grid-gap: 10px;
  gap: 10px;
}

#marginTop {
  margin-top: 10px;
}

#divbutton {
}

  .TitleContainer {
    font-size: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin: 40px 0px 30px 75px;
    display: flex;
    flex-direction: column;
    margin-left: 45%;
    width: 650px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

    .botaoEnviar{
        background-color: #1E90FF;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 3px;
        margin-right: 8px;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }
    
.TitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  flex-direction: column;
  margin-left: 45%;
  width: 650px;
}

.Title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.properties-data-safe-area {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.properties-photos-list {
  display: flex;
}
.properties-photos-list a {
  display: flex;
  text-decoration: none;
}

.properties-photos-list p:last-of-type {
  content: none;
}
.properties-btn-wrapp {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  grid-gap: 18px;
  gap: 18px;
}

.properties-btn-wrapp button {
  padding: 12px 24px;
  border-radius: 24px;
  border: 1px solid #dfdfdf;
}
.properties-btn-wrapp button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.properties-btn-wrapp #deleteButton {
  margin: 0;
}

#propertiesApproveButton {
}
#propertiesApproveButton {
}

#warnContainer {
    display: flex;
    flex-direction: column;
  }
  
  #InputWarnTitle {
    width: 400px;
    border-radius: 10px;
  }
  
  #InputWarnBody {
    width: 400px;
    height: 200px;
    max-width: 600px;
    border-radius: 10px;
    padding-top: 8px;
  }
  
  #InputFile {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  
  #warnBtn {
    border: none;
  }
  
  #warnBtn:hover {
    background: rgb(72, 219, 251);
  }
  
.TitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  flex-direction: column;
  margin-left: 45%;
  width: 650px;
}

.Title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.properties-data-safe-area {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.properties-photos-list {
  display: flex;
}
.properties-photos-list a {
  display: flex;
  text-decoration: none;
}

.properties-photos-list p:last-of-type {
  content: none;
}
.properties-btn-wrapp {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  grid-gap: 18px;
  gap: 18px;
}

.properties-btn-wrapp button {
  padding: 12px 24px;
  border-radius: 24px;
  border: 1px solid #dfdfdf;
}
.properties-btn-wrapp button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.properties-btn-wrapp #deleteButton {
  margin: 0;
}

#propertiesApproveButton {
}
#propertiesApproveButton {
}

#homeContent {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

#homeWelcome {
  padding-left: 60px;
  padding-top: 3%;
  width: 1200px;
}

#homeCenter {
  padding-top: 58px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 50px;
  grid-template-rows: repeat(4, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 58px;
}

#plusIcon {
  width: 55px;
  height: 55px;
  margin-right: 10px;
}

#option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0px 0px 50px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  width: 350px;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}

#homeCenter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 50px;
  grid-template-rows: repeat(4, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 58px;
  padding-top: 58px;
}

#home-card {
  border-radius: 21px;
  box-shadow: 0px 2px 4px -1px #00000012;
  box-shadow: 0px 4px 6px -1px #0000001f;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: 200ms;
}

#home-card:hover {
  box-shadow: 0px 4px 10px -1px #0000009a;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}

#home-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#home-card:nth-child(4) img {
  object-fit: contain;
}

#home-card:nth-child(5) img {
  object-fit: contain;
}

#home-card:nth-child(11) img {
  object-fit: contain;
}

#home-card:nth-child(8) img {
  object-fit: contain;
}
#home-card:nth-child(9) img {
  object-fit: contain;
}

#home-card:nth-child(12) img {
  object-fit: contain;
}

#TopContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 135px;
  gap: 135px;
  align-items: flex-start;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#WarnAddButton {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #ee6f57;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  margin-right: 50%;
  margin-bottom: 0;
  margin-top: 20px;
}
.WarnDate2 {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
}

.WarnTitle {
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
}

#ButtonContainer {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 0;
  width: 100%;
}

#deleteButtonWarnings {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#deleteButtonWarnings:hover {
  background-color: rgb(255, 64, 64);
}

#EditButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover {
  background-color: #1d6995;
}

#managementCenter {
  box-sizing: border-box;
  width: 100%;
  padding-left: 440px;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.managementOptionsWrap {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 0px;
  box-sizing: border-box;
  align-items: center;
  border-right: 1px solid rgb(231, 231, 231);
}
.mngm-opt-title {
  display: flex;
  align-items: center;
  width: 80%;
  grid-gap: 40px;
  gap: 40px;
}

.mngm-opt-return-img {
  height: 80px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.managementOptionsWrap > div > p {
  font-size: 48px;
}

.managementModalWrap {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.managementOptionsContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 80px;
  gap: 80px;
  margin-top: 100px;
  width: 80%;
  align-items: center;
}

.managementOptionsContainer > button {
  width: 300px;
  height: 70px;
  color: white;
  background-color: #00334E;
  border-style: none;
  border-radius: 16px;
  font-size: 18px;
  transition: ease-in;
}

.managementOptionsContainer > button:hover {
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 50%);
  background-color: #EE6F57;
}

.managementModalContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.managementModalContainer > h1 {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.mngm-content {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 5%;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.mngm-add-manutencao {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  
  background-color: #EE6F57;
  border-radius: 8px;
  font-weight: bold;
}

.mngm-add-manutencao:hover {
  cursor: pointer;
  background-color: #EE6F57;
}

.mngm-add-manutencao > img {
  height: 30px;
}

.mngm-mntc-eqp-title {
  width: 100%;
}

.mngm-mntc-eqp-list {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.mngm-mntc-eqp-item {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 24px 24px;
  box-sizing: border-box;
  height: 80px;
  border: 1px solid black;
  border-radius: 16px;
}
/*branch main*/

.pageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 650px;
}

#pageTitle {
  padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

#formArea {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-left: 40%;
}

.registerInput {
  height: 120px;
  width: 415px;
  background-color: #efefef;
  margin: 0px 30px 40px 70px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registerInputTitle {
  padding: 15px 0px 15px 0px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

#registerInputText {
  margin-left: 20px;
  width: 340px;
}

#signUpCondominium {
  top: 710px;
  left: 50px;
  border: none;
  width: 150px;
}

#signUpCondominiumText {
  padding-left: 15px;
  padding-right: 15px;
}

#signUpCondominium:hover {
  background: rgb(72, 219, 251);
}

#signUpCondominium:focus {
  background: rgb(54, 153, 175);
}

#loadingBox {
  margin-left: 40px;
}

#errorMessage2 {
  font-family: "Montserrat", sans-serif;
  padding-left: 26px;
  font-size: 14px;
}

#content {
  margin-left: 500px;
}

#picker {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: 500px;
}

#link {
  margin-left: 30px;
  margin-top: 40px;
}


  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
    
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    color: #fff;
    margin-top: 10px;
    
    border-radius: 5px;
  }
  #logoprofile {
    height: 250px;
    width: 250px;
    border-radius: 100%;
  }

  .clearButton {
    background-color: rgb(255, 64, 64);
    color: #fff;
    border-radius: 50px;
    border: none;
  
    box-sizing: border-box;
  
    width: 100%;
    padding: 8px 0;
    cursor: pointer;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 17px;
    transition: 0.2s;
    margin-top: 36px;
    position: relative;
  }

  .clearButton:hover {
    background: black;
  }

  #ImgPerfil{
    height: 250px;
    width: 250px;
    border-radius: 50%;
  }

#DivImgPerfil{
  align-items: center;
}
#picPerfil {
  height: 250px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
#logoPerfil {
  height: auto;
  width: 100%;
  align-self: center;
}
#x {
  margin-left: 26vw;
}

#listArea {
  margin-left: 26vw;
  grid-gap: 10px;
  gap: 10px;
}

#marginTop {
  margin-top: 10px;
}

#divbutton {
}

    .botaoEnviar{
        background-color: #1E90FF;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 3px;
        margin-right: 8px;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }
    
  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
    
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    color: #fff;
    margin-top: 10px;
    
    border-radius: 5px;
  }
  #logoprofile {
    height: 250px;
    width: 250px;
    border-radius: 100%;
  }
  #picPerfil {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  #logoPerfil {
    height: auto;
    width: 100%;
    align-self: center;
  }
  .TitleContainer {
    font-size: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin: 40px 0px 30px 75px;
    display: flex;
    flex-direction: column;
    margin-left: 45%;
    width: 650px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
.ta{
}
.pageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 650px;
}

.pageTitleContainerx {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 650px;
  
}

.propagandaCheckbox {
  height: 12px;
  width: 12px;
  border: none;
  color: yellow;
  border-radius: 4px;
}

#pageTitle {
  padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

#formArea {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-left: 40%;
}

.registerInput {
  height: 120px;
  width: 415px;
  background-color: #efefef;
  margin: 0px 16px 8px 0px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registerInputTitle {
  padding: 15px 0px 15px 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

#registerInputText {
  margin-left: 20px;
  width: 340px;
}

#signUpCondominium {
  top: 710px;
  left: 50px;
  border: none;
  width: 150px;
}

#signUpCondominiumText {
  padding-left: 15px;
  padding-right: 15px;
}

#signUpCondominium:hover {
  background: rgb(72, 219, 251);
}

#signUpCondominium:focus {
  background: rgb(54, 153, 175);
}

#loadingBox {
  margin-left: 40px;
}

#errorMessage2 {
  font-family: "Montserrat", sans-serif;
  padding-left: 26px;
  font-size: 14px;
}

#content {
  margin-left: 500px;
  margin-bottom: 20px;
}

#filePicker {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

#advertButton {
  width: 300px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid black;
  background-color: black;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

#states {
  padding: 15px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
}

#stateOption {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

#column {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

#inputArea {
  height: 100%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: left;
}

#propagandaInput {
  font-size: 15px;
}

#propContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.InputProp {
  border: 1px solid black;
  width: 400px;
  border-radius: 10px;
}

.image-erro {
  color: red;
}
#homeCenter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  gap: 24px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 50px;
  grid-template-rows: repeat(4, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 58px;
  padding-top: 58px;
}

#home-card {
  border-radius: 21px;
  box-shadow: 0px 2px 4px -1px #00000012;
  box-shadow: 0px 4px 6px -1px #0000001f;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: 200ms;
}

#home-card:hover {
  box-shadow: 0px 4px 10px -1px #0000009a;
}

#home-card h1 {
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #00334e;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-family: "Open Sans";
}

#home-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#home-card:nth-child(4) img {
  object-fit: contain;
}

#home-card:nth-child(5) img {
  object-fit: contain;
}

#home-card:nth-child(11) img {
  object-fit: contain;
}

#home-card:nth-child(8) img {
  object-fit: contain;
}
#home-card:nth-child(9) img {
  object-fit: contain;
}

#home-card:nth-child(12) img {
  object-fit: contain;
}

  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    margin-top: 10px;
    border-radius: 5px;
    color: #fff;
  }
  #picPerfil {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  #logoPerfil {
    height: auto;
    width: 100%;
    align-self: center;
  }
  

#warnContainer {
    display: flex;
    flex-direction: column;
  }
  
  #InputWarnTitle {
    width: 400px;
    border-radius: 10px;
  }
  
  #InputWarnBody {
    width: 400px;
    height: 200px;
    max-width: 600px;
    border-radius: 10px;
    padding-top: 8px;
  }
  
  #InputFile {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  
  #warnBtn {
    border: none;
  }
  
  #warnBtn:hover {
    background: rgb(72, 219, 251);
  }
  
.WarnDate{
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  
}

.WarnTitle{
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  
  
}
.backButton{
  border: none;
  background-color: transparent;
}

#approveButton{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    position: absolute;
   
    margin-left: 500px;
    margin-top: 65px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
  }

  #approveButton:hover{
  
    background-color: rgb(56, 216, 51);;
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    
    margin-left: 86%;
    margin-top:70px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
    
   
  }

  #cross3{
    width:14px;
    height: 2px;
    margin-right: 13px;
    margin-top: 5px;
    background: black;
    border-radius: 15px;
    position: absolute;
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
    transition: 0.5s;
  }
  
  #cross4{
    width:27px;
    height: 2px;
    margin-left: 15px;
    background: black;
    border-radius: 15px;
    position: absolute;
    transition: 0.5s;
    -webkit-transform: rotate(-47.5deg);
            transform: rotate(-47.5deg);
  }
  #deleteButtonResident{
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
    background-color: transparent;
    
    margin-left: 86%;
    margin-top:70px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
    border: 1px solid black;
    
  }
  #deleteButtonResident:hover{
    
    background-color: rgb(255, 64, 64);;
   
  }

  
#managementCenter {
  box-sizing: border-box;
  width: 100%;
  padding-left: 440px;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.managementOptionsWrap {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 0px;
  box-sizing: border-box;
  align-items: center;
  border-right: 1px solid rgb(231, 231, 231);
}
.mngm-opt-title {
  display: flex;
  align-items: center;
  width: 80%;
  grid-gap: 40px;
  gap: 40px;
}

.mngm-opt-return-img {
  height: 80px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.managementOptionsWrap > div > p {
  font-size: 48px;
}

.managementModalWrap {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.managementOptionsContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 80px;
  gap: 80px;
  margin-top: 100px;
  width: 80%;
  align-items: center;
}

.managementOptionsContainer > button {
  width: 300px;
  height: 70px;
  color: white;
  background-color: #00334E;
  border-style: none;
  border-radius: 16px;
  font-size: 18px;
  transition: ease-in;
}

.managementOptionsContainer > button:hover {
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 50%);
  background-color: #EE6F57;
}

.managementModalContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.managementModalContainer > h1 {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.mngm-content {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 5%;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.mngm-add-manutencao {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  
  background-color: #EE6F57;
  border-radius: 8px;
  font-weight: bold;
}

.mngm-add-manutencao:hover {
  cursor: pointer;
  background-color: #EE6F57;
}

.mngm-add-manutencao > img {
  height: 30px;
}

.mngm-mntc-eqp-title {
  width: 100%;
}

.mngm-mntc-eqp-list {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.mngm-mntc-eqp-item {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 24px 24px;
  box-sizing: border-box;
  height: 80px;
  border: 1px solid black;
  border-radius: 16px;
}
/*branch main*/

.pageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 650px;
}

#pageTitle {
  padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

#formArea {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-left: 40%;
}

.registerInput {
  height: 120px;
  width: 415px;
  background-color: #efefef;
  margin: 0px 30px 40px 70px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registerInputTitle {
  padding: 15px 0px 15px 0px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

#registerInputText {
  margin-left: 20px;
  width: 340px;
}

#signUpCondominium {
  top: 710px;
  left: 50px;
  border: none;
  width: 150px;
}

#signUpCondominiumText {
  padding-left: 15px;
  padding-right: 15px;
}

#signUpCondominium:hover {
  background: rgb(72, 219, 251);
}

#signUpCondominium:focus {
  background: rgb(54, 153, 175);
}

#loadingBox {
  margin-left: 40px;
}

#errorMessage2 {
  font-family: "Montserrat", sans-serif;
  padding-left: 26px;
  font-size: 14px;
}

#content {
  margin-left: 500px;
}

#picker {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: 500px;
}

#link {
  margin-left: 30px;
  margin-top: 40px;
}


#managementCenter {
  box-sizing: border-box;
  width: 100%;
  padding-left: 440px;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.managementOptionsWrap {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 0px;
  box-sizing: border-box;
  align-items: center;
  border-right: 1px solid rgb(231, 231, 231);
}
.mngm-opt-title {
  display: flex;
  align-items: center;
  width: 80%;
  grid-gap: 40px;
  gap: 40px;
}

.mngm-opt-return-img {
  height: 80px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.managementOptionsWrap > div > p {
  font-size: 48px;
}

.managementModalWrap {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.managementOptionsContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 80px;
  gap: 80px;
  margin-top: 100px;
  width: 80%;
  align-items: center;
}

.managementOptionsContainer > button {
  width: 300px;
  height: 70px;
  color: white;
  background-color: #00334E;
  border-style: none;
  border-radius: 16px;
  font-size: 18px;
  transition: ease-in;
}

.managementOptionsContainer > button:hover {
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 50%);
  background-color: #EE6F57;
}

.managementModalContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.managementModalContainer > h1 {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.mngm-content {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 5%;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.mngm-add-manutencao {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  
  background-color: #EE6F57;
  border-radius: 8px;
  font-weight: bold;
}

.mngm-add-manutencao:hover {
  cursor: pointer;
  background-color: #EE6F57;
}

.mngm-add-manutencao > img {
  height: 30px;
}

.mngm-mntc-eqp-title {
  width: 100%;
}

.mngm-mntc-eqp-list {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.mngm-mntc-eqp-item {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 24px 24px;
  box-sizing: border-box;
  height: 80px;
  border: 1px solid black;
  border-radius: 16px;
}
/*branch main*/

.pageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 650px;
}

#pageTitle {
  padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

#formArea {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-left: 40%;
}

.registerInput {
  height: 120px;
  width: 415px;
  background-color: #efefef;
  margin: 0px 30px 40px 70px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registerInputTitle {
  padding: 15px 0px 15px 0px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

#registerInputText {
  margin-left: 20px;
  width: 340px;
}

#signUpCondominium {
  top: 710px;
  left: 50px;
  border: none;
  width: 150px;
}

#signUpCondominiumText {
  padding-left: 15px;
  padding-right: 15px;
}

#signUpCondominium:hover {
  background: rgb(72, 219, 251);
}

#signUpCondominium:focus {
  background: rgb(54, 153, 175);
}

#loadingBox {
  margin-left: 40px;
}

#errorMessage2 {
  font-family: "Montserrat", sans-serif;
  padding-left: 26px;
  font-size: 14px;
}

#content {
  margin-left: 500px;
}

#picker {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: 500px;
}

#link {
  margin-left: 30px;
  margin-top: 40px;
}


.spacePageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  align-items: center;
}

.pageTitleContainer{
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  

}

.pageTitle {
  padding-left: 2rem;
  font-size: 2.25rem;
  line-height: 5.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
}

.formikFieldArea {
  padding: 10px 20px 5px 20px;
  height: 120px;
  width: 80%;
  background-color: #efefef;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.formikFieldLabel {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-left: 0.5;
  cursor: text;
  overflow: hidden;
  width: auto;
  margin-bottom: 0.75rem;
}

.formikFieldText {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: calc(100% - 2rem);
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikErrorMessage {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
  cursor: text;
  color: #fa541c;
  margin-left: 1rem;
}

.formikSubmitButton {
  background-color: rgb(221, 221, 221);
  border: 2.23px solid #000000;
  box-sizing: border-box;
  border-radius: 50px;
  width: 155px;
  height: 50px;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 23px;
  transition: 0.25s;
  position: relative;
  transition-duration: 0.3s;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.formikSubmitButton:hover {
  background: rgba(72, 219, 251, 0.3);
}

.formikSubmitButton:focus {
  background: rgba(54, 153, 175, 0.3);
}

.formikSubmitButtonText {
  padding-left: 15px;
  padding-right: 15px;
}

.ml4 {
  margin-left: 4.1rem;
}

.infoLabel {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.dataContainer-lg {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  height: 24.5rem;
  padding: 2rem;
  margin: 1rem 1rem 1rem 1rem;
}

.actionButton {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.actionButton.delete:hover {
  background-color: #e85d04;
}
.actionButton.edit:hover {
  background-color: rgba(72, 219, 251, 0.3);
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 0px 40px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

.spacePageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  align-items: center;
}

.pageTitle {
  padding-left: 2rem;
  font-size: 2.25rem;
  line-height: 5.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
}

.formikFieldArea {
  padding: 10px 20px 5px 20px;
  height: 120px;
  width: 90%;
  background-color: #efefef;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.formikFieldLabel {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-left: 0.5;
  cursor: text;
  overflow: hidden;
  width: auto;
  margin-bottom: 0.75rem;
}

.formikFieldText {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: calc(100% - 2rem);
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikFieldText.datePicker {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: 100%;
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikErrorMessage {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
  font-size: 14px;
  cursor: text;
  color: #fa541c;
  margin-left: 1rem;
}

.formikSubmitButton {
  background-color: rgb(221, 221, 221);
  border: 2.23px solid #000000;
  box-sizing: border-box;
  border-radius: 50px;
  width: 155px;
  height: 50px;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 23px;
  transition: 0.25s;
  position: relative;
  transition-duration: 0.3s;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.formikSubmitButton:hover {
  background: rgba(72, 219, 251, 0.3);
}

.formikSubmitButton:focus {
  background: rgba(54, 153, 175, 0.3);
}

.formikSubmitButtonText {
  padding-left: 15px;
  padding-right: 15px;
}

.ml4 {
  margin-left: 4.1rem;
}

.infoLabel {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.dataContainer {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 2rem;
  margin: 1rem 1rem 1rem 1rem;
  
  
}

.dataContainer.lg {
  height: 17rem;
}
.dataContainer.sm {
  height: 9rem;
}

.dataContainer.md {
  height: 16rem;
}

.actionButton {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.actionButton.delete:hover {
  background-color: #e85d04;
}
.actionButton.edit:hover {
  background-color: rgba(72, 219, 251, 0.3);
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 0px 40px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

.spacePageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 75px;
  display: flex;
  align-items: center;
}

.pageTitleContainer {
  display: flex;
  flex-direction: row;
  margin-left: 3%;
}
.pageTitle {
  padding-left: 2rem;
  font-size: 2.25rem;
  line-height: 5.625rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
}

.fileSelector {
  background-color: rgba(72, 219, 251, 0.3);
  border-radius: 15px;
  cursor: pointer;
  margin: 10px;
  padding: 6px 20px;
  width: 90%;
}

.formikFieldArea {
  padding: 10px 20px 5px 20px;
  height: 170px;
  width: 100%;
  background-color: #efefef;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.formikFieldLabel {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-left: 0.5;
  cursor: text;
  overflow: hidden;
  width: auto;
  margin-bottom: 0.75rem;
}

.formikFieldText {
  height: 30%;
  text-align: start;
  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: rgba(72, 219, 251, 0.3);
  border: none;
  outline: none;
  transition: 0.25s;
  width: calc(100% - 2rem);
  display: flex;
  padding: 0rem 1rem 0rem 1rem;
}

.formikErrorMessage {
  font-family: "Montserrat", sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
  cursor: text;
  color: #fa541c;
  margin-left: 1rem;
}

.formikSubmitButton {
  background-color: rgb(221, 221, 221);
  border: 2.23px solid #000000;
  box-sizing: border-box;
  border-radius: 50px;
  width: 155px;
  height: 50px;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 23px;
  transition: 0.25s;
  position: relative;
  transition-duration: 0.3s;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.formikSubmitButton:hover {
  background: rgba(72, 219, 251, 0.3);
}

.formikSubmitButton:focus {
  background: rgba(54, 153, 175, 0.3);
}

.formikSubmitButtonText {
  padding-left: 15px;
  padding-right: 15px;
}

.ml4 {
  margin-left: 4.1rem;
}

.infoLabel {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.archieveText {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.dataContainer {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  
  padding: 1.5rem;
  margin: 1rem 1rem 1rem 1rem;
  overflow: hidden;
}

.actionButton {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.actionButton.delete:hover {
  background-color: #e85d04;
}
.actionButton.edit:hover {
  background-color: rgba(72, 219, 251, 0.3);
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 0px 40px;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
}

#TopContainer1{
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


#WarnAddButton{
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: black;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate{
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  
}

.WarnTitle{
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
  
  
}

#ButtonContainer{
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 73%;
}

#deleteButtonWarnings{
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom:20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  
}
#deleteButtonWarnings:hover{
  
  background-color: rgb(255, 64, 64);
 
}

#EditButton{
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom:20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover{
  background-color: #1d6995;
}

  .TitleContainer {
    font-size: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin: 40px 0px 30px 75px;
    display: flex;
    flex-direction: column;
    margin-left: 45%;
    width: 650px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

.react-select-container {
  width: calc(100% - 2rem);
}

.react-select__control {
  background-color: rgba(72, 219, 251, 0.3) !important;
  border-radius: 30px !important;
  padding: 0.1rem;
  border: none;
}

.react-select__multi-value {
  background-color: rgba(72, 219, 251, 0.4) !important;
  border-radius: 30px !important;
  padding: 0.2rem;
}

.react-select__menu-list {
  background-color: rgba(72, 219, 251, 0.3) !important;
}
.react-select__option:hover {
  background-color: rgba(72, 219, 251, 0.4) !important;
}

.react-select__indicator {
  color: hsl(0, 0%, 60%) !important;
}

.formikFieldSelectFile {
  background-color: rgba(72, 219, 251, 0.3);
  text-align: center;
  width: calc(100% - 2rem);
  border: none;
  border-radius: 30px;
  height: 2rem;
  padding: 0.4rem;
}

#TopContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 135px;
  gap: 135px;
  align-items: flex-start;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#WarnAddButton {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 30px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #ee6f57;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.WarnDate {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  margin-right: 50%;
  margin-bottom: 0;
  margin-top: 20px;
}
.WarnDate2 {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
}

.WarnTitle {
  font-size: 24px;
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
}

#ButtonContainer {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-left: 0;
  width: 100%;
}

#deleteButtonWarnings {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#deleteButtonWarnings:hover {
  background-color: rgb(255, 64, 64);
}

#EditButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
  font-size: 25px;
}
#EditButton:hover {
  background-color: #1d6995;
}

#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background: rgb(72, 219, 251);
}

