  #usernameTextprofile {
    margin-left : 5px;
    margin-bottom: 8px;
  }
  #jobTextprofile {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
  }
  #profileTextprofile {
    margin: 5px;
    margin-top: 10px;
  }
  #nameprofile {
    display: flex;
    justify-content: center;
    background: #00334E;
    margin-top: 10px;
    border-radius: 5px;
    color: #fff;
  }
  #picPerfil {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  #logoPerfil {
    height: auto;
    width: 100%;
    align-self: center;
  }
  
