#warnContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#InputWarnTitle {
  width: 400px;
  border-radius: 10px;
}

#InputWarnBody {
  width: 400px;
  height: 200px;
  max-width: 600px;
  border-radius: 10px;
  padding-top: 8px;
}

#InputFile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

#warnBtn {
  border: none;
  padding: 12px 0;
}

#warnBtn:hover {
  background:#EE6F57;
}
