#x {
  margin-left: 26vw;
}

#listArea {
  margin-left: 26vw;
  gap: 10px;
}

#marginTop {
  margin-top: 10px;
}

#divbutton {
}
