.ta{
}
.pageTitleContainer {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 650px;
}

.pageTitleContainerx {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 40px 0px 30px 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 650px;
  
}

.propagandaCheckbox {
  height: 12px;
  width: 12px;
  border: none;
  color: yellow;
  border-radius: 4px;
}

#pageTitle {
  padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

#formArea {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-left: 40%;
}

.registerInput {
  height: 120px;
  width: 415px;
  background-color: #efefef;
  margin: 0px 16px 8px 0px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registerInputTitle {
  padding: 15px 0px 15px 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

#registerInputText {
  margin-left: 20px;
  width: 340px;
}

#signUpCondominium {
  top: 710px;
  left: 50px;
  border: none;
  width: 150px;
}

#signUpCondominiumText {
  padding-left: 15px;
  padding-right: 15px;
}

#signUpCondominium:hover {
  background: rgb(72, 219, 251);
}

#signUpCondominium:focus {
  background: rgb(54, 153, 175);
}

#loadingBox {
  margin-left: 40px;
}

#errorMessage2 {
  font-family: "Montserrat", sans-serif;
  padding-left: 26px;
  font-size: 14px;
}

#content {
  margin-left: 500px;
  margin-bottom: 20px;
}

#filePicker {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#advertButton {
  width: 300px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid black;
  background-color: black;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

#states {
  padding: 15px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
}

#stateOption {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

#column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#inputArea {
  height: 100%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: left;
}

#propagandaInput {
  font-size: 15px;
}

#propContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.InputProp {
  border: 1px solid black;
  width: 400px;
  border-radius: 10px;
}

.image-erro {
  color: red;
}