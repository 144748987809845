#listArea {
  height: 100%;
  width: 100%;

  margin-left: 88%;
  margin-top: 10%;

  flex-direction: column;
  flex-wrap: wrap;
}

#x {
  margin-left: 85%;
}

#infoNames {
  display: flex;
  flex-direction: row;
  padding: 25px 20px 10px 20px;
}

#infoTitle {
  margin: 10px 20px 5px;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.data-container {
  max-width: 600px;
  width: 90%;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 22px;
  margin-bottom: 30px;
  word-break: break-all;
  transition: 0.25s;
}

.data-container:hover {
  border-left: 5px solid #00334e;
}

#data-safe-area {
  margin: 20px;
}

.condominium-name-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.manager-name-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}
.state-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}
.cep-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

.unities-title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#deleteButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;

  margin-left: 86%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#deleteButton:hover {
  background-color: rgb(255, 64, 64);
}

#editButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;

  margin-left: 86%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#editButton:hover {
  background-color: rgb(64, 255, 80);
}

#blockButton {
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;

  margin-left: 0;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid black;
}
#blockButton:hover {
  background-color: #1d6995;
}

#cross1 {
  width: 27px;
  height: 2px;
  background: black;
  border-radius: 15px;
  position: absolute;
  transform: rotate(45deg);
}

#cross2 {
  width: 27px;
  height: 2px;
  background: black;
  border-radius: 15px;
  position: absolute;
  transform: rotate(-45deg);
}

#searchInput {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-left: 15px;
  outline: none;
}

#searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: none;
  height: 50px;
  width: 270px;
  text-align: start;

  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  background-color: #f6f5f5;
  border: none;
  padding-left: 20px;
  outline: none;
  transition: 0.25s;
}
