.WarnDate{
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 20px;
  
}

.WarnTitle{
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  
  
}