#circle {
  height: 65px;
  width: 65px;
  background-color: #00334e;
  border-radius: 100%;
  display: flex;
  padding-top: 20px;
  align-items: center;

  transition: 0.5s;
  cursor: pointer;
}

#circle:hover {
  background: #ee6f57;
  transition: 0.5s;
}

ul li {
  list-style: none;
  padding-left: 25px;

  transition: 0.5s;
}

ul li:hover {
  transition: 0.5s;
  padding-left: 20px;
}

ul li span {
  display: flex;
  width: 20px;
  height: 20px;
  border: 4px solid #ffff;
  transition: 0.5s;

  transform: translateY(-50%) rotate(-45deg);

  border-bottom: none;
  border-right: none;
}

ul li:hover span {
  width: 20px;
  height: 20px;
  border: 4px solid #ffff;
  transition: 0.5s;

  transform: translateY(-50%) rotate(-45deg);

  border-bottom: none;
  border-right: none;
}

ul li span:before {
  width: 20px;
  height: 4px;
  border-radius: 15px;
  background: #ffff;
  content: "";
  margin-top: 10px;
  margin-right: 55px;
  transform-origin: left;
  transform: rotate(45deg) translate(-2px, -1px) scale(0);
  transition: 0.5s;
  border-bottom: none;
  border-right: none;
}

ul li:hover span:before {
  transform: rotate(45deg) translate(-2px, -1px) scaleX(1);
}
